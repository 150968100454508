import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _622facf8 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _2bdda002 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _df94f82e = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _f821d58c = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _26f714b7 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _884a1516 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3b974d1f = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _6cf42b58 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _e4ca34a8 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _6a18b750 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _70959920 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _4c971ed4 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _52cf8c08 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _4a167467 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _43d83ba8 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _f33be0e6 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _1b995b44 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _622facf8,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _2bdda002,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _df94f82e,
    name: "callback"
  }, {
    path: "/downloads",
    component: _f821d58c,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _26f714b7,
    name: "guests-details"
  }, {
    path: "/login",
    component: _884a1516,
    name: "login"
  }, {
    path: "/maintenance",
    component: _3b974d1f,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _6cf42b58,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _e4ca34a8,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _6a18b750,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _70959920,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _4c971ed4,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _52cf8c08,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _4a167467,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _43d83ba8,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _f33be0e6,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _1b995b44,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
